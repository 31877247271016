import { Component, For, Show } from "solid-js";

const features = {
  ["sync_chat"]: {
    ["name"]: "Sync Chat",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["sync_chat_customisation"]: {
    ["name"]: "Sync Chat - Customisation",
    ["free_value"]: false,
    ["premium_value"]: true,
  },
  ["server_status"]: {
    ["name"]: "Server Status",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["server_status_buttons_limit"]: {
    ["name"]: "Server Status - Buttons Limit",
    ["free_value"]: "2 buttons",
    ["premium_value"]: "25 buttons",
  },
  ["sync_player_name"]: {
    ["name"]: "Sync Player Name",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["sync_player_name_customisation"]: {
    ["name"]: "Sync Player Name - Customisation",
    ["free_value"]: false,
    ["premium_value"]: true,
  },
  ["player_stats"]: {
    ["name"]: "Player Stats",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["player_stats_custom"]: {
    ["name"]: "Player Stats - Custom Stats",
    ["free_value"]: false,
    ["premium_value"]: true,
  },
  ["screenshots"]: {
    ["name"]: "Player Screenshots",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["ig_report_bug"]: {
    ["name"]: "In-Game Report Bug",
    ["free_value"]: false,
    ["premium_value"]: true,
    ["inDev"]: true,
  },
  ["team_vocal"]: {
    ["name"]: "Team Vocal",
    ["free_value"]: false,
    ["premium_value"]: true,
    ["inDev"]: true,
  },
  ["server_status_alert"]: {
    ["name"]: "Server Status - Alert",
    ["free_value"]: "Default Message",
    ["premium_value"]: "Custom Message",
    ["inDev"]: true,
  },
  ["guild_link"]: {
    ["name"]: "Guild Links",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["guild_link_limit"]: {
    ["name"]: "Guild Links - Limit",
    ["free_value"]: "2 links",
    ["premium_value"]: "infinite",
  },
  ["logs_relay"]: {
    ["name"]: "Server Logs Relay",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["logs"]: {
    ["name"]: "WebPanel Logs",
    ["free_value"]: "24 hours",
    ["premium_value"]: "infinite",
  },
  ["players"]: {
    ["name"]: "Players Database",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["user_profiles"]: {
    ["name"]: "User Profiles",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["user_stats"]: {
    ["name"]: "Player Stats",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["user_stats_custom_value"]: {
    ["name"]: "Player Stats - Custom Value",
    ["free_value"]: false,
    ["premium_value"]: true,
  },
  ["leaderboards"]: {
    ["name"]: "Player Leaderboards",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["servers"]: {
    ["name"]: "Servers",
    ["free_value"]: "1 server",
    ["premium_value"]: "infinite",
  },
  ["sync_bans"]: {
    ["name"]: "Sync Bans",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["sync_roles"]: {
    ["name"]: "Sync Role",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["sync_roles_limit"]: {
    ["name"]: "Sync Role - Limit",
    ["free_value"]: "4 roles",
    ["premium_value"]: "infinite",
  },
  ["sync_kicks"]: {
    ["name"]: "Sync Kicks",
    ["free_value"]: false,
    ["premium_value"]: true,
    ["inDev"]: true,
  },
  ["sync_warns"]: {
    ["name"]: "Sync Warns",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["report_bugs"]: {
    ["name"]: "Report Bugs",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["sync_team"]: {
    ["name"]: "Sync Teams Roles",
    ["free_value"]: true,
    ["premium_value"]: true,
    ["inDev"]: true,
  },
  ["sync_team_limit"]: {
    ["name"]: "Sync Teams Roles - Limit",
    ["free_value"]: "2 teams",
    ["premium_value"]: "infinite",
    ["inDev"]: true,
  },
  ["guild_tickets"]: {
    ["name"]: "Guild Tickets",
    ["free_value"]: true,
    ["premium_value"]: true,
    ["inDev"]: true,
  },
  ["guild_suggestions"]: {
    ["name"]: "Guild Suggestions",
    ["free_value"]: true,
    ["premium_value"]: true,
    ["inDev"]: true,
  },
  ["guild_verification"]: {
    ["name"]: "Guild Verification",
    ["free_value"]: "Default Message",
    ["premium_value"]: "Custom Message",
  },
  ["guild_verification_role_limit"]: {
    ["name"]: "Guild Verification - Role Limit",
    ["free_value"]: "2 role",
    ["premium_value"]: "infinite",
  },
  ["force_ply_link"]: {
    ["name"]: "Force Player Link",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["maintenance_white_list"]: {
    ["name"]: "Maintenance",
    ["free_value"]: true,
    ["premium_value"]: true,
  },
  ["rcon"]: {
    ["name"]: "RCON Execution",
    ["free_value"]: false,
    ["premium_value"]: true,
  },
  ["reward"]: {
    ["name"]: "Reward",
    ["free_value"]: false,
    ["premium_value"]: true,
    ["inDev"]: true,
  },
};

const offers = {
  ["free"]: {
    ["name"]: "Free",
    ["sub_price"]: "free, nada, nothing, niente",
    ["description"]: "Get access to all the basic features",
    ["promote_features"]: ["All Free Features", "Basic Support"],
    ["button"]: "Setup Server",
    ["link"]: "/dashboard/guilds",
    ["price"]: 0,
    ["permanent"]: false,
  },
  ["subscribe"]: {
    ["name"]: "Subscribe",
    ["sub_price"]: "guilds / month",
    ["description"]: "Get access to all features and customisation",
    ["promote_features"]: ["All Premium Features", "Premium Discord Role", "Priority Support"],
    ["button"]: "Subscribe on Discord",
    ["link"]: "https://discord.com/application-directory/1110121451501129758/premium",
    ["price"]: 7.99,
    ["permanent"]: false,
  },
  ["buy"]: {
    ["name"]: "One Time",
    ["sub_price"]: "guilds / lifetime",
    ["description"]: "Get access to all features and customisation",
    ["promote_features"]: [
      "All Premium Features",
      "Custom Bot",
      "Premium Discord Role",
      "Priority Support",
      "Lifetime Access",
    ],
    ["button"]: "Buy on Gmod Store",
    ["link"]: "https://www.gmodstore.com/market/view/gmod-integration",
    ["price"]: 69.99,
    ["permanent"]: true,
  },
};

const category = {
  ["server"]: {
    ["name"]: "Server",
    ["features"]: [
      features["servers"],
      features["server_status"],
      features["server_status_buttons_limit"],
      features["server_status_alert"],
      features["logs"],
      features["logs_relay"],
      features["players"],
      features["reward"],
    ],
  },
  ["utility"]: {
    ["name"]: "Utility",
    ["features"]: [
      features["guild_link"],
      features["guild_link_limit"],
      features["screenshots"],
      features["guild_tickets"],
      features["guild_suggestions"],
      features["ig_report_bug"],
      features["team_vocal"],
      features["maintenance_white_list"],
      features["rcon"],
      features["report_bugs"],
    ],
  },
  ["data"]: {
    ["name"]: "Data & Statistics",
    ["features"]: [
      features["user_profiles"],
      features["user_stats"],
      features["user_stats_custom_value"],
      features["leaderboards"],
    ],
  },
  ["security"]: {
    ["name"]: "Security",
    ["features"]: [
      features["guild_verification"],
      features["guild_verification_role_limit"],
      features["force_ply_link"],
      features["sync_bans"],
      features["sync_kicks"],
      features["sync_warns"],
    ],
  },
  ["synchronization"]: {
    ["name"]: "Synchronization",
    ["features"]: [
      features["sync_chat"],
      features["sync_chat_customisation"],
      features["sync_player_name"],
      features["sync_player_name_customisation"],
      features["sync_roles"],
      features["sync_roles_limit"],
      features["sync_team"],
      features["sync_team_limit"],
    ],
  },
};

const faq = [
  {
    question: "What are the differences between the free and the premium version ?",
    answer:
      "Gmod Integration Premium offers more features and customisation and is a way to support the project. You can see the full list of features in the plans comparison table above.",
  },
  {
    question: "What is a server and what is a guilds ?",
    answer:
      "The terms server refers to a Garry's Mod server and guilds refers to a Discord guilds (aka discord server).",
  },
  {
    question: "What will happen if I lose my premium subscription or if I don't renew it ?",
    answer:
      "You will lose access to the premium features and customisation. You won't lose your data but can't access it anymore.",
  },
  {
    question: "Can I get a refund or cancel my subscription ?",
    answer:
      "We do not process payments ourselves. We use Discord and GmodStore for payment processing. As such, we redirect you to their respective refund policies for any inquiries regarding refunds or cancellations.",
  },
  {
    question: "Who can buy the premium version ?",
    answer:
      "Anyone can buy the monthly version for your guilds directly on Discord. The lifetime version is only" +
      " available on Gmod Store and can be bought only once per guilds and only by a guilds Administrator.",
  },
  {
    question: "How many premium can I buy ?",
    answer: "You can buy only one lifetime version, but you can buy multiple monthly versions for different guilds.",
  },
  {
    question: "Can I transfer my premium version to another guilds ?",
    answer: "Only the lifetime version is transferable to another guilds, you need to be Administrator of both guilds.",
  },
  {
    question: "Why so many features are in development ?",
    answer:
      "Because I'm a solo developer the project is pretty new and big, so I need time to develop all the features. But I'm working hard to make them available as soon as possible.",
  },
];

const Premium: Component = () => {
  return (
    <>
      <div class="max-w-screen-xl mx-auto flex flex-col p-6 gap-16">
        <div class="flex flex-col items-center justify-center gap-6 mt-12">
          <h1 class="text-5xl font-bold">Premium</h1>
          <p class="text-lg">Upgrade your community to the next level with customisation and more features</p>
        </div>

        <div class="flex flex-wrap justify-around gap-6 py-6">
          <For each={Object.keys(offers)}>
            {(key) => (
              <a
                class="shadowHover w-[340px] border border-primary rounded-lg p-6 gap-6 flex flex-col"
                href={offers[key].link}
              >
                <h2 class="text-2xl font-bold">{offers[key].name}</h2>
                <div class="gap-2 flex flex-col">
                  <h1 class="text-5xl font-bold">${offers[key].price}</h1>
                  <h3 class="text-font-secondary">{offers[key].sub_price}</h3>
                </div>
                <ul class="flex flex-col gap-5">
                  <For each={offers[key].promote_features}>
                    {(feature) => (
                      <li class="flex gap-4 items-center">
                        <i class="fa-solid fa-check text-[24px] text-green font-bold"></i>
                        <p>{feature}</p>
                      </li>
                    )}
                  </For>
                </ul>
                <div />
                <div class="btn btn-primary w-full mt-auto">{offers[key].button}</div>
              </a>
            )}
          </For>
        </div>

        <div class="p-6">
          <h1 class="text-4xl font-bold text-center mb-12">Plans Comparison</h1>
          <div class="flex flex-col gap-12">
            <For each={Object.keys(category)}>
              {(key) => (
                <div class="w-full border-radius">
                  <table class="table border border-primary rounded-lg border-separate noBorderBottomTable">
                    <thead>
                      <tr class="text-white text-lg">
                        <th class="w-1/3">{category[key].name}</th>
                        <th class="w-1/4 text-center">Free</th>
                        <th class="w-1/4 text-center">Premium</th>
                      </tr>
                    </thead>
                    <tbody class="text-[1.1em]">
                      <For each={category[key].features}>
                        {(feature) => (
                          <tr>
                            <td class="text-left">
                              <Show when={feature.inDev}>
                                <div
                                  class="tooltip tooltip-warning z-10"
                                  data-tip="This feature is still in development and not available."
                                >
                                  {feature.name}
                                  <i class="fa-solid fa-triangle-exclamation text-warning text-lg ml-2"></i>
                                </div>
                              </Show>
                              <Show when={!feature.inDev}>{feature.name}</Show>
                            </td>
                            <td class="min-w-1/4 text-center">
                              {feature.free_value === true ? (
                                <i class="fa-solid fa-check text-green text-[1.4em]"></i>
                              ) : feature.free_value === false ? (
                                <i class="fa-solid fa-times text-red text-[1.4em]"></i>
                              ) : feature.free_value === "infinite" ? (
                                <i class="fa-solid fa-infinity text-purple text-[1.4em]"></i>
                              ) : (
                                feature.free_value
                              )}
                            </td>
                            <td class="w-1/4 text-center">
                              {feature.premium_value === true ? (
                                <i class="fa-solid fa-check text-green text-[1.4em]"></i>
                              ) : feature.premium_value === false ? (
                                <i class="fa-solid fa-times text-red text-[1.4em]"></i>
                              ) : feature.premium_value === "infinite" ? (
                                <i class="fa-solid fa-infinity text-purple text-[1.4em]"></i>
                              ) : (
                                feature.premium_value
                              )}
                            </td>
                          </tr>
                        )}
                      </For>
                    </tbody>
                  </table>
                </div>
              )}
            </For>
          </div>
        </div>

        <div class="p-6">
          <h1 class="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h1>
          <div class="join join-vertical w-full">
            <For each={faq}>
              {({ question, answer }) => (
                <div class="collapse collapse-arrow join-item border border-primary rounded-lg">
                  <input type="radio" name="my-accordion-4" />
                  <div class="collapse-title font-medium">{question}</div>
                  <div class="collapse-content text-font-secondary">
                    <p>{answer}</p>
                  </div>
                </div>
              )}
            </For>
          </div>
        </div>
      </div>
    </>
  );
};

export default Premium;
