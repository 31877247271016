import { Component, createResource, Show } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { fetchAPI, getUrlWithActualParams } from "../../../../utils";
import { guildChannels, guildChannelsRefetch } from "../GuildInformations";
import AdminChannelSelector from "../../../../components/AdminChannelSelector";

const fetchScreenshot = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/screenshots", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const ServerScreenshots: Component = () => {
  const [screenshots, { mutate: screenshotsMutate }] = createResource("screenshot", fetchScreenshot);

  const sendScreenshots = async (channelID: string) => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/screenshots", "POST", {
      channelID
    });
    if (!res.ok) {
      return;
    }
    const screenshot = await res.json();
    screenshotsMutate(screenshot);
    return screenshot;
  };

  const removeScreenshots = async () => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/screenshots", "DELETE");
    if (!res.ok) {
      return;
    }
    screenshotsMutate({});
    return {};
  };

  return (
    <>
      <AdminChannelSelector id="select_channel_modal" callback={sendScreenshots} />

      <AdminPanel title="Screenshots"
                  description="Set a channel to send the screenshots taken by players to Discord.">
        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Screenshots Channels:</span>
          <Show when={!screenshots.loading && screenshots().channelID}
                fallback={<span class="label-text">No screenshots message</span>}>
            <a
              href={getUrlWithActualParams(
                `https://discord.com/channels/:guildID/${screenshots().channelID}`
              )}
              class="link link-hover text-soft-purple hover:text-soft-purple-active"
              target="_blank"
            >
              {guildChannels().find((channel) => channel.id === screenshots().channelID)?.name}
            </a>
          </Show>
        </div>
        <div class="flex gap-4">
          <Show when={!screenshots.loading && screenshots().channelID}>
            <button
              class="btn btn-warning"
              onClick={async () => {
                await removeScreenshots();
              }}
            >
              Remove Channel
            </button>
          </Show>
          <button
            class="btn btn-primary"
            onclick="select_channel_modal.showModal()"
            onClick={() => guildChannelsRefetch()}
          >
            Select Channel
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default ServerScreenshots;
