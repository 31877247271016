import { A } from "@solidjs/router";
import { Component, createResource, createSignal, For, onMount, Show } from "solid-js";
import { discordUser, isAdmin, isLogged, setDiscordUser, setIsAdmin, setIsLogged } from "../event";
import { API_FQDN, fetchAPI, isProduction } from "../utils";
import logo from "../assets/brand/logo.png";
import { useI18n } from "../i18n";
import { createStore } from "solid-js/store";

const lang = [
  {
    en: {
      title: "English",
      emoji: "🇬🇧",
    },
  },
  {
    fr: {
      title: "Français",
      emoji: "🇫🇷",
    },
  },
  {
    ru: {
      title: "Русский",
      emoji: "🇷🇺",
    },
  },
  {
    de: {
      title: "Deutsch",
      emoji: "🇩🇪",
    },
  },
  {
    es: {
      title: "Español",
      emoji: "🇪🇸",
    },
  },
  {
    it: {
      title: "Italiano",
      emoji: "🇮🇹",
    },
  },
  {
    tr: {
      title: "Türkçe",
      emoji: "🇹🇷",
    },
  },
];

export const Header: Component = () => {
  const [isOpen, setIsOpen] = createSignal(false);
  const { t, locale, updateLocale } = useI18n();

  const [navLinks] = createStore([
    {
      title: t("main:documentation"),
      fontAwesomeIcon: "fas fa-book",
      href: "/docs",
    },
    {
      title: t("main:add_bot"),
      fontAwesomeIcon: "fas fa-robot",
      href: "/invite",
    },
    {
      title: t("main:support"),
      fontAwesomeIcon: "fab fa-discord",
      href: "/discord",
    },
    {
      title: t("main:premium"),
      fontAwesomeIcon: "fas fa-crown",
      href: "/premium",
      color: "text-amber-400",
      hoverColor: "hover:text-amber-300",
    },
  ]);

  const [navDropdownLinks] = createStore([
    {
      title: "User",
      content: [
        {
          title: t("main:account"),
          fontAwesomeIcon: "fas fa-user",
          href: "/account",
        },
        {
          title: t("main:notifications"),
          fontAwesomeIcon: "fas fa-bell",
          href: "/notifications",
        },
      ],
    },
    {
      title: "Main",
      content: [
        {
          title: t("main:servers_ranking"),
          fontAwesomeIcon: "fas fa-server",
          href: "/servers",
        },
        {
          title: t("main:dashboard"),
          fontAwesomeIcon: "fas fa-tachometer-alt",
          href: "/dashboard/guilds",
        },
        {
          title: t("main:admin_dashboard"),
          fontAwesomeIcon: "fas fa-screwdriver-wrench",
          href: "/dashboard/admins/informations",
          condition: () => {
            return isAdmin();
          },
        },
      ],
    },
    {
      title: "Other",
      content: [
        {
          title: t("main:logout"),
          fontAwesomeIcon: "fas fa-right-from-bracket",
          href: "/logout",
        },
      ],
    },
  ]);

  onMount(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (isOpen() && !event.target.closest(".dropdown")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  onMount(() => {
    const discordID = localStorage.getItem("discordID");
    const token = localStorage.getItem("accessToken");
    const expirationDate = localStorage.getItem("expirationDate");

    if (!discordID || !token || !expirationDate) {
      setIsLogged(false);
      console.log("Failed to get the user data from the local storage");
      return;
    }

    const expirationDateParsed = new Date(expirationDate);
    if (expirationDateParsed <= new Date()) {
      setIsLogged(false);
      console.log("The token has expired");
      return;
    }

    fetch(`${API_FQDN}/users/${discordID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          setIsLogged(false);
          console.log("Failed to get the user data from the API");
        }
      })
      .catch(() => {
        setIsLogged(false);
        console.log("Failed to get the user data from the API");
      });

    const discordUser = JSON.parse(localStorage.getItem("discordUser")!);
    if (!discordUser) {
      setIsLogged(false);
      console.log("Failed to get the user data from the local storage");
      return;
    }

    if (discordUser && discordUser.error) {
      localStorage.removeItem("discordUser");
      setIsLogged(false);
      console.log("The user data is invalid");
      return;
    }

    setDiscordUser(discordUser);
    setIsLogged(true);

    fetch(`${API_FQDN}/users?discordID=${discordUser.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((userInfo) => {
        if (userInfo.rank && userInfo.rank === "developer") {
          setIsAdmin(true);
        }
      });
  });

  const [userNotifications, { refetch: refetchUserNotifications }] = createResource("userNotifications", async () => {
    return await fetchAPI("/users/:discordID/notifications", "GET")
      .then((res) => {
        if (!res.ok) {
          return [];
        } else {
          return res.json();
        }
      })
      .then((json) => {
        let unreadNotifications = 0;
        json.forEach((notification: any) => {
          if (!notification.read) {
            unreadNotifications++;
          }
        });
        return unreadNotifications > 9 ? "9+" : unreadNotifications;
      });
  });

  return (
    <>
      <div className="navbar bg-background max-w-screen-2xl mx-auto p-4">
        <div className="flex-1">
          <img src={logo} alt="logo" width="60" height="60" />
          <A href="/" class="text-2xl font-bold ml-4 text-font whitespace-nowrap">
            Gmod Integration
          </A>

          <For each={navLinks}>
            {({ title, fontAwesomeIcon, href, color, hoverColor }) => (
              <A
                href={href}
                class={
                  "ml-6 whitespace-nowrap " +
                  (color ? color : "text-base-content") +
                  " " +
                  (hoverColor ? hoverColor : "hover:text-zinc-300")
                }
              >
                <i class={fontAwesomeIcon + " mr-1"}></i> {title}
              </A>
            )}
          </For>
        </div>

        {/* TODO: remove the <show>*/}
        <Show when={!isProduction()}>
          <div className="flex-none">
            <div className="dropdown dropdown-end">
              <div tabIndex="0" role="button" className="btn btn-ghost hover:bg-transparent text-lg">
                <span class="text-base-content">
                  {lang.find((l) => Object.keys(l)[0] === locale())?.[locale()].emoji}
                </span>
                <i class="fas fa-angle-down ml-1"></i>
              </div>
              <ul class="menu dropdown-content mt-3 z-[1] p-4 gap-4 shadow bg-primary rounded-md w-[160px]">
                <For each={lang}>
                  {(lang) => (
                    <li class="flex">
                      <button
                        class="p-0 gap-4 m-0 hover:font-bold hover:text-white text-gray-100 whitespace-nowrap hover:bg-transparent"
                        onClick={() => {
                          updateLocale(Object.keys(lang)[0]);
                          window.location.reload();
                        }}
                      >
                        <span class="flex justify-center w-6 items-center text-base-content">
                          {lang[Object.keys(lang)[0]].emoji}
                        </span>
                        {lang[Object.keys(lang)[0]].title}
                      </button>
                    </li>
                  )}
                </For>
              </ul>
            </div>
          </div>
        </Show>

        <Show when={isLogged()}>
          <div className="flex-none">
            <div className="dropdown dropdown-end">
              <div
                tabIndex="0"
                role="button"
                className="btn btn-ghost hover:bg-transparent"
                onClick={() => setIsOpen(true)}
              >
                <div className="w-10 rounded-full overflow-hidden">
                  <img alt="Tailwind CSS Navbar component" src={discordUser().displayAvatarURL} />
                </div>
                <p class="text-[1.3em] text-soft-purple mx-2">{discordUser().globalName}</p>
                <i class={`text-[1.3em] fas ${isOpen() ? "fa-angle-up" : "fa-angle-down"}`}></i>
              </div>
              <ul
                tabIndex="0"
                class="menu dropdown-content mt-3 z-[1] p-4 gap-4 shadow bg-primary rounded-md min-w-[200px]"
              >
                <For each={navDropdownLinks}>
                  {({ title, content }, index) => (
                    <>
                      <For each={content}>
                        {({ title, fontAwesomeIcon, href, condition }) => (
                          <>
                            <Show when={!condition || condition()}>
                              <li class="flex">
                                <A
                                  href={href}
                                  class="p-0 m-0 hover:font-bold hover:text-white text-gray-100 whitespace-nowrap hover:bg-transparent"
                                >
                                  <i class={fontAwesomeIcon + " " + "mr-1 flex w-[24px] justify-center"}></i>
                                  {title}
                                  <Show
                                    when={
                                      title === "Notifications" && !userNotifications.loading && userNotifications()
                                    }
                                  >
                                    <span class="badge badge-warning ml-2">{userNotifications()}</span>
                                  </Show>
                                </A>
                              </li>
                            </Show>
                          </>
                        )}
                      </For>
                      {index() < navDropdownLinks.length - 1 && <hr class="border-1 border-[#6d6d6d]" />}
                    </>
                  )}
                </For>
              </ul>
            </div>
          </div>
        </Show>

        <Show when={!isLogged()}>
          <div className="flex-none">
            <A href="/login" class="btn btn-primary">
              Login with Discord
            </A>
          </div>
        </Show>
      </div>

      <hr class="border-[1px] border-primary" />
    </>
  );
};
