import { createContext, createSignal, useContext } from "solid-js";

import en from "./locales/en.json";
import fr from "./locales/fr.json";
import ru from "./locales/ru.json";
import de from "./locales/de.json";
import es from "./locales/es.json";
import it from "./locales/it.json";
import tr from "./locales/tr.json";

const translations = {
  en,
  fr,
  ru,
  de,
  es,
  it,
  tr,
};

const I18nContext = createContext();

const getNestedTranslation = (obj, key) => {
  return key.split(":").reduce((o, i) => (o ? o[i] : null), obj);
};

export const I18nProvider = (props) => {
  const [locale, setLocale] = createSignal(localStorage.getItem("locale") || "en");

  const t = (key) => {
    let translation = getNestedTranslation(translations[locale()], key);
    if (!translation && locale() !== "en") {
      translation = getNestedTranslation(translations["en"], key);
    }
    return translation || key;
  };

  const updateLocale = (newLocale) => {
    if (!translations[newLocale]) {
      return;
    }
    localStorage.setItem("locale", newLocale);
    setLocale(newLocale);
  };

  return <I18nContext.Provider value={{ t, locale, updateLocale }}>{props.children}</I18nContext.Provider>;
};

export const useI18n = () => {
  const context = useContext(I18nContext);

  if (!context) {
    throw new Error("useI18n must be used within an I18nProvider");
  }

  return context;
};
