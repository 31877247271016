import { Component, onMount } from "solid-js";
import { setDiscordUser, setIsLogged } from "../event";
import { useNavigate } from "@solidjs/router";

const Logout: Component = () => {
  const navigate = useNavigate();

  onMount(async () => {
    localStorage.clear();
    setIsLogged(false);
    setDiscordUser({
      id: "",
      username: "",
      globalName: "",
      discriminator: "",
      avatarURL: "",
      displayAvatarURL: "",
    });
    navigate("/");
  });

  return (
    <>
      <a class="link-hover" href="/">
        Click here if you are not redirected.
      </a>
    </>
  );
};

export default Logout;
