import { Component, For } from "solid-js";
import { A } from "@solidjs/router";
import logo from "../assets/brand/logo.png";

const footerLinks = [
  {
    title: "Services",
    links: [
      {
        title: "Dashboard",
        href: "/dashboard"
      },
      {
        title: "Servers Ranking",
        href: "/servers"
      }
      // {
      //   title: "Search Users",
      //   href: "/users",
      // },
      // {
      //   title: "Leaderboard",
      //   href: "/leaderboard",
      // }
    ]
  },
  {
    title: "Links",
    links: [
      {
        title: "Documentation",
        href: "/docs"
      },
      {
        title: "Premium",
        href: "/premium"
      },
      {
        title: "Support",
        href: "/support"
      }
    ]
  },
  {
    title: "Legal",
    links: [
      {
        title: "Terms of Use",
        href: "/legal/terms"
      },
      {
        title: "Privacy Policy",
        href: "/legal/privacy"
      }
    ]
  }
];

export const Footer: Component = () => {
  return (
    <>
      <div class="flex flex-shrink-0 flex-col">
        <hr class="border-[1px] border-primary" />
        <footer class="footer p-10 bg-background text-base-content max-w-screen-2xl mx-auto">
          <For each={footerLinks}>
            {({ title, links }) => (
              <nav>
                <h6 class="footer-title text-white opacity-100">{title}</h6>
                <For each={links}>
                  {({ title, href }) => (
                    <A class="link link-hover" href={href}>
                      {title}
                    </A>
                  )}
                </For>
              </nav>
            )}
          </For>
        </footer>
        <hr class="border-[1px] border-primary" />
        <footer class="footer px-10 py-4 bg-background text-base-content max-w-screen-2xl mx-auto">
          <aside class="items-center grid-flow-col">
            <img src={logo} alt="logo" width="40" height="40" />
            <div class="ml-2 flex flex-col">
              <h3 class="text-font font-bold">Gmod Integration</h3>
              <p>© 2024 Gmod Integration. All rights reserved</p>
            </div>
          </aside>
        </footer>
      </div>
    </>
  );
};
