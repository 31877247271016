import { Component, createResource, For, Show } from "solid-js";
import { fetchAPI } from "../utils";
import DashboardMiddleware from "../middleware/DashboardMiddleware";
import { AddError } from "../index";

const Notifications: Component = () => {
  const [userNotifications, { mutate: mutateUserNotifications }] = createResource("userNotifications", async () => {
    return await fetchAPI("/users/:discordID/notifications", "GET")
      .then(async (res) => {
        if (!res.ok) {
          return [];
        } else {
          return res.json();
        }
      })
      .then((json) => {
        return json.sort((a: any, b: any) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
      });
  });

  function setNotificationsAsRead(id: number) {
    fetchAPI(`/users/:discordID/notifications/${id}`, "PATCH").then((res) => {
      if (res.ok) {
        const json = res.json();
        mutateUserNotifications((prevNotifications) =>
          (prevNotifications || []).map((notification) => {
            if (notification.id === id) {
              return { ...notification, read: true };
            }
            return notification;
          }),
        );
      } else {
        AddError("Failed to mark the notification as read");
      }
    });
  }

  return (
    <>
      <DashboardMiddleware />
      <div class="flex flex-col p-4 gap-4 max-w-screen-xl mx-auto w-full">
        <h2 class="text-2xl py-4 font-bold">Notifications</h2>

        <div class="border border-primary rounded-lg">
          <table class="table">
            <thead>
              <tr class="text-white text-l">
                <th class="w-1/6">Date</th>
                <th class="w-1/6">Type</th>
                <th>Message</th>
                <th class="w-1/6 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <For each={userNotifications()}>
                {(notification) => (
                  <tr>
                    <td class="w-1/6">{new Date(notification.createdAt).toLocaleString()}</td>
                    <td class="w-1/6">{notification.type}</td>
                    <td>{notification.message}</td>
                    <td class="w-1/6 text-center">
                      <Show
                        when={!notification.read}
                        fallback={<i class="fa-solid cursor-pointer fa-eye-slash text-gray-400"></i>}
                      >
                        <div class="tooltip tooltip-info" data-tip="Mark as Read">
                          <i class="fa-solid fa-eye" onClick={() => setNotificationsAsRead(notification.id)}></i>
                        </div>
                      </Show>
                    </td>
                  </tr>
                )}
              </For>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Notifications;
