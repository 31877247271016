import { Component, createEffect, createSignal, For, Show } from "solid-js";
import { A, useLocation } from "@solidjs/router";
import { getUrlWithActualParams } from "../utils";

enum linkBadge {
  SOON = "Soon",
  TODO = "Todo",
  NEW = "New",
}

const linksData = [
  {
    name: "Guilds",
    condition: (url: string) => !url.includes("/servers/"),
    back: "/dashboard/guilds",
    content: [
      {
        emoji: "fa-info",
        label: "Informations",
        url: "/dashboard/guilds/:guildID/config",
      },
      {
        label: "Custom Bot",
        emoji: "fa-robot",
        url: "/dashboard/guilds/:guildID/config/bot",
      },
      {
        emoji: "fa-link",
        label: "Links",
        url: "/dashboard/guilds/:guildID/config/links",
      },
      {
        emoji: "fa-shield-halved",
        label: "Verification",
        url: "/dashboard/guilds/:guildID/config/verification",
      },
      {
        emoji: "fa-solid fa-wand-magic-sparkles",
        label: "Auto-Role",
        url: "/dashboard/guilds/:guildID/config/auto-role",
      },
      {
        emoji: "fa-lightbulb",
        label: "Suggestions",
        url: "/dashboard/guilds/:guildID/config/suggestions",
        badge: linkBadge.TODO,
      },
      {
        emoji: "fa-ticket",
        label: "Tickets",
        url: "/dashboard/guilds/:guildID/config/tickets",
        badge: linkBadge.TODO,
      },
      {
        emoji: "fa-server",
        label: "Servers",
        url: "/dashboard/guilds/:guildID/config/servers",
      },
    ],
  },
  {
    name: "Servers",
    condition: (url: string) => url.includes("/servers/"),
    back: "/dashboard/guilds/:guildID/config/servers",
    content: [
      {
        emoji: "fa-info",
        label: "Informations",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID",
      },
      {
        emoji: "fa-chart-simple",
        label: "Status",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/status",
      },
      {
        emoji: "fa-user",
        label: "Players",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/players",
      },
      {
        emoji: "fa-address-book",
        label: "Roles",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/roles",
      },
      {
        emoji: "fa-comments",
        label: "Chats",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/chats",
        badge: linkBadge.NEW,
      },
      {
        emoji: "fa-id-card",
        label: "Pseudo",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/pseudo",
      },
      {
        emoji: "fa-gift",
        label: "Rewards",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/rewards",
        badge: linkBadge.TODO,
      },
      {
        emoji: "fa-eye",
        label: "Logs",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/logs",
      },
      {
        emoji: "fa-explosion",
        label: "Errors",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/errors",
      },
      {
        emoji: "fa-bug",
        label: "Bugs Report",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/bugs",
      },
      {
        emoji: "fa-camera",
        label: "Screenshots",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/screenshots",
      },
      {
        emoji: "fa-vote-yea",
        label: "Vote",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/votes",
      },
      {
        emoji: "fa-video",
        label: "Streams",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/streams",
        badge: linkBadge.TODO,
      },
      {
        emoji: "fa-ban",
        label: "Bans",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/bans",
        badge: linkBadge.TODO,
      },
      {
        emoji: "fa-bug",
        label: "Bugs Report",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/bugs",
        badge: linkBadge.TODO,
      },
      {
        emoji: "fa-ticket",
        label: "Tickets",
        url: "/dashboard/guilds/:guildID/config/servers/:serverID/tickets",
        badge: linkBadge.TODO,
      },
    ],
  },
];

class navLink {
  emoji: string;
  label: string;
  url: string;
  badge?: linkBadge;
  current?: boolean;

  constructor(emoji: string, label: string, url: string, current?: boolean, badge?: linkBadge) {
    this.emoji = emoji;
    this.label = label;
    this.url = url;
    this.current = current;
    this.badge = badge;
  }
}

export const DashboardMenu: Component = () => {
  const [onlyShowEmoji, setOnlyShowEmoji] = createSignal(false);
  const [hoverExpand, setHoverExpand] = createSignal(false);
  const [backUrl, setBackUrl] = createSignal("/dashboard/guilds");
  const [actualLinksData, setActualLinksData] = createSignal([] as navLink[]);

  function refreshLinks(pathname: string) {
    linksData.forEach((category) => {
      if (category.condition(pathname)) {
        setBackUrl(category.back);
        setActualLinksData(
          category.content.map(
            (link) =>
              new navLink(link.emoji, link.label, link.url, getUrlWithActualParams(link.url) === pathname, link.badge),
          ),
        );
      }
    });
  }

  const location = useLocation();
  createEffect(() => {
    refreshLinks(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div
        class="flex flex-col p-4 gap-2"
        classList={{
          "min-w-[240px]": !onlyShowEmoji() || hoverExpand(),
        }}
        onMouseEnter={() => setHoverExpand(true)}
        onMouseLeave={() => setHoverExpand(false)}
      >
        <div class="flex flex-col">
          <A class="flex items-center p-2 gap-4 hover:font-bold" href={getUrlWithActualParams(backUrl())}>
            <i class="fa-solid fa-arrow-left flex justify-center items-center min-w-[24px] min-h-[24px]"></i>
            <Show when={!onlyShowEmoji() || hoverExpand()}>Back</Show>
          </A>
        </div>

        <hr class="border-[1px] border-primary" />

        <For each={actualLinksData()}>
          {(link) => (
            <Show when={!link.badge || link.badge !== linkBadge.TODO}>
              <A
                href={getUrlWithActualParams(link.url)}
                class="flex items-center gap-4 p-2 rounded-md"
                classList={{
                  "bg-primary text-white": link.current,
                  "text-font-secondary": link.badge && link.badge === linkBadge.SOON,
                  "hover:bg-primary hover:text-white": !link.current && (!link.badge || link.badge !== linkBadge.SOON),
                }}
                onClick={(e) => {
                  if (link.badge && link.badge === linkBadge.SOON) {
                    e.preventDefault();
                  }
                }}
              >
                <i class={`fas ${link.emoji} flex justify-center items-center min-w-[24px] min-h-[24px]`}></i>
                <span
                  class="text-nowrap"
                  classList={{
                    visible: !onlyShowEmoji() || hoverExpand(),
                    hidden: onlyShowEmoji() && !hoverExpand(),
                  }}
                >
                  {link.label}
                </span>
                <Show when={link.badge}>
                  <div
                    class="badge badge-outline"
                    classList={{
                      visible: !onlyShowEmoji() || hoverExpand(),
                      hidden: onlyShowEmoji() && !hoverExpand(),
                    }}
                  >
                    {link.badge}
                  </div>
                </Show>
              </A>
            </Show>
          )}
        </For>

        <div class="flex flex-col mt-auto hover:cursor-pointer" onClick={() => setOnlyShowEmoji(!onlyShowEmoji())}>
          <hr class="border-[1px] border-primary mb-4" />
          <div class="flex items-center p-2 gap-4 hover:font-bold">
            <i
              class="fas flex justify-center items-center min-w-[24px] min-h-[24px]"
              classList={{
                "fa-angle-double-right": onlyShowEmoji(),
                "fa-angle-double-left": !onlyShowEmoji(),
              }}
            ></i>
            {!onlyShowEmoji() ? <span>Collapse</span> : hoverExpand() ? <span>Expand</span> : ""}
          </div>
        </div>
      </div>
      <div class="border-[1px] border-primary" />
    </>
  );
};
