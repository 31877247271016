import { Component, createResource, createSignal, For, Show } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { fetchAPI, NeedWebsocket, PremiumFeature } from "../../../../utils";
import { guildRoles } from "../GuildInformations";
import AdminModal from "../../../../components/AdminModal";

interface RoleSync {
  serverID: string;
  roleID: string;
  userGroup: string;
  enable: boolean;
}

const ServerRoles: Component = () => {
  const [pseudoDirection, { mutate: mutatePseudoDirection }] = createResource("pseudoDirection", async () => {
    return fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/sync_role_direction", "GET").then(async (res) => {
      if (!res.ok) throw new Error("An error occurred while fetching the pseudo direction.");
      return await res.json() || {};
    });
  });

  const [selectRole, setSelectRole] = createSignal({} as RoleSync);

  const [rolesSync, { mutate: mutateRolesSync }] = createResource("rolesSync", async () => {
    return fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/roles", "GET").then(async (res) => {
      if (!res.ok) throw new Error("An error occurred while fetching the roles sync.");
      return await res.json() || {};
    });
  });

  function getSelectorClassList(direction: string) {
    return !pseudoDirection.loading ? pseudoDirection().value === direction : false;
  }

  function updateSyncPseudoDirection(direction: string) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/sync_role_direction", "PUT", {
      value: direction
    }).then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("An error occurred while updating the pseudo direction.");
      }
    }).then((data) => {
      mutatePseudoDirection(data);
    });
  }

  async function addRole(roleID: string) {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/roles/${roleID}`, "POST").then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("An error occurred while adding the role.");
      }
    }).then((data) => {
      mutateRolesSync((prev) => [...prev, data]);
    });
  }

  function editRole() {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/roles/${selectRole().roleID}`, "PUT", selectRole()).then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("An error occurred while editing the role.");
      }
    }).then((data) => {
      mutateRolesSync((prev) => prev.map((r) => (r.roleID === data.roleID ? data : r)));
    });
  }

  function deleteRole(roleID: string) {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/roles/${roleID}`, "DELETE").then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("An error occurred while deleting the role.");
      }
    }).then((data) => {
      mutateRolesSync((prev) => prev.filter((r) => r.roleID !== data.roleID));
    });
  }

  return (
    <>
      <NeedWebsocket />
      <PremiumFeature message="In free plan you are limited at 4 roles sync." />

      <AdminModal title="Select Role" id="select_role_modal">
        <Show when={!guildRoles.loading && !rolesSync.loading} fallback={<div>Loading...</div>}>
          <div class="form-control">
            <select
              class="select select-bordered"
              onchange="select_role_modal.close()"
              onChange={async (e) => {
                await addRole(e.currentTarget.value);
              }}
            >
              <option value="0">Select a Role</option>
              <For each={guildRoles()}>
                {(role) => {
                  if (!rolesSync().find((v) => v.roleID === role.id)) {
                    return <option value={role.id}>{role.name}</option>;
                  }
                }}
              </For>
            </select>
          </div>
        </Show>
      </AdminModal>

      <AdminModal title="Edit Role" id="edit_role_modal">
        <Show when={!guildRoles.loading && !rolesSync.loading}>
          <div class="form-control">
            <label class="label">
              <span class="label-text">Discord Role</span>
            </label>
            <select class="select select-bordered" disabled>
              <option selected>{guildRoles().find((r) => r.id === selectRole().roleID)?.name}</option>
            </select>
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">User Group</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              value={selectRole().userGroup}
              onInput={(e) => {
                selectRole().userGroup = e.currentTarget.value;
              }}
            />
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">Active</span>
            </label>
            <select
              class="select select-bordered"
              value={selectRole().enable ? "true" : "false"}
              onChange={(e) => {
                selectRole().enable = e.currentTarget.value === "true";
              }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <button
            class="btn btn-primary mt-2"
            onclick="edit_role_modal.close()"
            onClick={async () => {
              editRole();
            }}
          >
            Save
          </button>
        </Show>
      </AdminModal>

      <AdminPanel
        title="Roles"
        description="Define the roles that are synchronized with your Discord server."
      >
        <div class="flex w-fit items-center">
          <span class="label-text mr-2 text-nowrap">Roles Synchronization Direction : </span>
          <select class="select select-bordered w-full max-w-xs" onChange={(e) => {
            updateSyncPseudoDirection(e.currentTarget.value);
          }}>
            <option value="discord-to-gmod" selected={getSelectorClassList("discord-to-gmod")}>From Discord to Gmod
            </option>
            <option value="gmod-to-discord" selected={getSelectorClassList("gmod-to-discord")}>From Gmod to Discord
            </option>
            <option value="both" selected={getSelectorClassList("both")}>Both Ways</option>
          </select>
        </div>
      </AdminPanel>

      <AdminPanel title="Roles Syncronized"
                  description="Define the roles that are synchronized with your Discord server." type="none">
        <table class="table">
          <thead>
          <tr class="text-white text-l">
            <th>Discord Role</th>
            <th>User Group</th>
            <th class="w-1/6 text-center">Active</th>
            <th class="w-1/6 text-center">Actions</th>
          </tr>
          </thead>
          <tbody>
          <Show when={!rolesSync.loading} fallback={
            <div class="flex justify-center h-36">
              <div class="loading loading-spinner loading-lg"></div>
            </div>
          }>
            <For each={rolesSync()}>
              {(roleSync) => (
                <tr>
                  <td>{guildRoles().find((r) => r.id === roleSync.roleID)?.name}</td>
                  <td>{roleSync.userGroup}</td>
                  <td>
                    <div class="flex gap-2 justify-center">
                      {roleSync.enable ? (
                        <i class="fas fa-check text-green"></i>
                      ) : (
                        <i class="fas fa-times text-red"></i>
                      )}
                    </div>
                  </td>
                  <td>
                    <div class="flex gap-2 justify-center">
                      <div class="tooltip tooltip-info" data-tip="Edit">
                        <i
                          class="hover:cursor-pointer fas fa-edit"
                          onclick="edit_role_modal.showModal()"
                          onClick={() => setSelectRole(roleSync)}
                        ></i>
                      </div>
                      <div class="tooltip tooltip-error" data-tip="Delete">
                        <i
                          class="hover:cursor-pointer fas fa-trash text-red"
                          onClick={() => deleteRole(roleSync.roleID)}
                        ></i>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </For>
          </Show>
          </tbody>
        </table>

        <div class="flex gap-4 p-4">
          <button class="btn btn-primary" onclick="select_role_modal.showModal()">
            Add Role
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default ServerRoles;
