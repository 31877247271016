import { Component, Match, Show, Switch } from "solid-js";

interface AdminPanelProps {
  title: string;
  description: string;
  children?: any;
  type?: string;
  info?: string;
  badge?: string;
}

const AdminPanel: Component<AdminPanelProps> = (props) => {
  return (
    <>
      <div class="border border-primary rounded-lg flex flex-col">
        <div class="flex flex-col gap-4 p-4">
          <div class="flex gap-4 items-center">
            <h2 class="text-xl font-bold">{props.title}</h2>
            <Show when={props.badge}>
              <div class="badge badge-outline">{props.badge}</div>
            </Show>
          </div>
          <p class="text-gray-500">{props.description}</p>
          <Show when={props.info}>
            <div class="flex gap-2 items-center text-gray-500">
              <i class="fa-solid fa-info-circle"></i>
              <span>{props.info}</span>
            </div>
          </Show>
        </div>

        <hr class="border-primary" />

        <Switch>
          <Match when={!props.type}>
            <div class="flex flex-col gap-4 p-4">{props.children}</div>
          </Match>
          <Match when={props.type === "none"}>{props.children}</Match>
        </Switch>
      </div>
    </>
  );
};

export default AdminPanel;
