import { Component, createEffect, createSignal, For, Show } from "solid-js";
import { A, useLocation } from "@solidjs/router";
import { getUrlWithActualParams } from "../utils";

enum linkBadge {
  SOON = "Soon",
  TODO = "Todo",
  NEW = "New",
}

const linksData = [
  {
    name: "Admin",
    condition: (url: string) => !url.includes("/servers/"),
    back: "/dashboard/admins",
    content: [
      {
        label: "Informations",
        emoji: "fa-info",
        url: "/dashboard/admins/informations"
      },
      {
        label: "Guilds",
        emoji: "fa-server",
        url: "/dashboard/admins/guilds"
      },
      {
        label: "Users",
        emoji: "fa-user",
        url: "/dashboard/admins/users"
      },
      {
        label: "Errors",
        emoji: "fa-bug",
        url: "/dashboard/admins/errors"
      },
      {
        label: "Logs",
        emoji: "fa-scroll",
        url: "/dashboard/admins/logs"
      }
    ]
  }
];

class navLink {
  emoji: string;
  label: string;
  url: string;
  badge?: linkBadge;
  current?: boolean;

  constructor(emoji: string, label: string, url: string, current?: boolean, badge?: linkBadge) {
    this.emoji = emoji;
    this.label = label;
    this.url = url;
    this.current = current;
    this.badge = badge;
  }
}

export const AdminMenu: Component = () => {
  const [onlyShowEmoji, setOnlyShowEmoji] = createSignal(false);
  const [hoverExpand, setHoverExpand] = createSignal(false);
  const [backUrl, setBackUrl] = createSignal("/dashboard/guilds");
  const [actualLinksData, setActualLinksData] = createSignal([] as navLink[]);

  function refreshLinks(pathname: string) {
    linksData.forEach((category) => {
      if (category.condition(pathname)) {
        setBackUrl(category.back);
        setActualLinksData(
          category.content.map(
            (link) =>
              new navLink(link.emoji, link.label, link.url, getUrlWithActualParams(link.url) === pathname, link.badge)
          )
        );
      }
    });
  }

  const location = useLocation();
  createEffect(() => {
    refreshLinks(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div
        class="flex flex-col p-4 gap-2"
        classList={{
          "min-w-[240px]": !onlyShowEmoji() || hoverExpand()
        }}
        onMouseEnter={() => setHoverExpand(true)}
        onMouseLeave={() => setHoverExpand(false)}
      >
        <div class="flex items-center p-2 gap-4">
          <i class="fas fa-screwdriver-wrench flex justify-center items-center min-w-[24px] min-h-[24px]"></i>
          <Show when={!onlyShowEmoji() || hoverExpand()}>Admin</Show>
        </div>

        <hr class="border-[1px] border-primary" />

        <For each={actualLinksData()}>
          {(link) => (
            <Show when={!link.badge || link.badge !== linkBadge.TODO}>
              <A
                href={getUrlWithActualParams(link.url)}
                class="flex items-center gap-4 p-2 rounded-md"
                classList={{
                  "bg-primary text-white": link.current,
                  "text-font-secondary": link.badge && link.badge === linkBadge.SOON,
                  "hover:bg-primary hover:text-white": !link.current && (!link.badge || link.badge !== linkBadge.SOON)
                }}
                onClick={(e) => {
                  if (link.badge && link.badge === linkBadge.SOON) {
                    e.preventDefault();
                  }
                }}
              >
                <i class={`fas ${link.emoji} flex justify-center items-center min-w-[24px] min-h-[24px]`}></i>
                <span
                  classList={{
                    visible: !onlyShowEmoji() || hoverExpand(),
                    hidden: onlyShowEmoji() && !hoverExpand()
                  }}
                >
                  {link.label}
                </span>
                <Show when={link.badge}>
                  <div
                    class="badge badge-outline"
                    classList={{
                      visible: !onlyShowEmoji() || hoverExpand(),
                      hidden: onlyShowEmoji() && !hoverExpand()
                    }}
                  >
                    {link.badge}
                  </div>
                </Show>
              </A>
            </Show>
          )}
        </For>

        <div class="flex flex-col mt-auto hover:cursor-pointer"
             onClick={() => setOnlyShowEmoji(!onlyShowEmoji())}>
          <hr class="border-[1px] border-primary mb-4" />
          <div class="flex items-center p-2 gap-4 hover:font-bold">
            <i
              class="fas flex justify-center items-center min-w-[24px] min-h-[24px]"
              classList={{
                "fa-angle-double-right": onlyShowEmoji(),
                "fa-angle-double-left": !onlyShowEmoji()
              }}
            ></i>
            {!onlyShowEmoji() ? <span>Collapse</span> : hoverExpand() ? <span>Expand</span> : ""}
          </div>
        </div>
      </div>
      <div class="border-[1px] border-primary" />
    </>
  );
};
