import { Component, createSignal } from "solid-js";
import { fetchAPI, getServer } from "../../../../utils";
import { useNavigate } from "@solidjs/router";
import AdminModal from "../../../../components/AdminModal";
import AdminPanel from "../../../../components/AdminPanel";
import defaultServer from "../../../../assets/defaultServer.png";

const ServerInformations: Component = () => {
  const navigate = useNavigate();
  const [server, setServer] = createSignal(getServer());
  const [showToken, setShowToken] = createSignal(false);

  const deleteServer = async () => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/servers/${server().id}`, "DELETE");
    if (!res.ok) {
      return;
    }
    navigate("/dashboard/guilds/:guildID/config/servers");
  };

  const editServer = async () => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/servers/${server().id}`, "PUT", server());
    if (!res.ok) {
      return;
    }
    const newServer = await res.json();
    setServer(newServer);
    localStorage.setItem("server", JSON.stringify(newServer));
  };

  const resetServerToken = async () => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/servers/${server().id}/token`, "POST");
    if (!res.ok) {
      return;
    }
    const newServer = await res.json();
    setServer(newServer);
    localStorage.setItem("server", JSON.stringify(newServer));
  };

  return (
    <>
      <AdminModal title="Edit Server" id="edit_server">
        <div class="form-control">
          <label class="label">
            <span class="label-text">Name</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().name}
            onInput={(e) => {
              server().name = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">Description</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().description}
            onInput={(e) => {
              server().description = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">Image</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().image}
            onInput={(e) => {
              server().image = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">IP</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().ip}
            onInput={(e) => {
              server().ip = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">Port</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={server().port}
            onInput={(e) => {
              server().port = e.currentTarget.value;
            }}
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">Public</span>
          </label>
          <select
            class="select select-bordered"
            value={server().isPublic ? "true" : "false"}
            onChange={(e) => {
              server().isPublic = e.currentTarget.value === "true";
            }}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <button
          class="btn btn-primary mt-2"
          onclick="edit_server.close()"
          onClick={async () => {
            await editServer();
          }}
        >
          Save
        </button>
      </AdminModal>

      <AdminPanel
        title="Informations"
        description="Here you can see some informations about your server, and edit some of them."
      >
        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Name:</span>
          <span class="label-text">{server().name}</span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Description:</span>
          <span class="label-text">{server().description}</span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Logo:</span>
          <img class="w-8 h-8 rounded object-cover" src={server().image || defaultServer} alt="Server Logo" />
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">IP:</span>
          <span class="label-text">{server().ip + ":" + server().port}</span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">ID:</span>
          <span class="label-text">{server().id}</span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Token:</span>
          <span
            class="label-text"
            classList={{ "hover:cursor-pointer": !showToken() }}
            onClick={() => setShowToken(true)}
          >
            {showToken() ? server().token : "Click to Show"}
          </span>
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Server Public:</span>
          <span class="label-text">{server().isPublic ? "Yes" : "No"}</span>
        </div>

        <div class="flex w-fit items-center gap-4">
          <button
            class="btn btn-warning"
            onClick={async () => {
              await deleteServer();
            }}
          >
            Delete Server
          </button>

          <button
            class="btn btn-warning"
            onClick={async () => {
              await resetServerToken();
            }}
          >
            Reset Server Token
          </button>

          <button class="btn btn-primary" onclick="edit_server.showModal()">
            Edit Server
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default ServerInformations;
