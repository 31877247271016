import { Component, createResource, createSignal, For, Match, Show, Switch } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { convertSecToTime, fetchAPI } from "../../../../utils";
import AdminModal from "../../../../components/AdminModal";

const fetchPlayers = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/players", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

function sortByName(a: any, b: any) {
  return a.name.localeCompare(b.name);
}

function sortByRank(a: any, b: any) {
  return a.rank - b.rank;
}

function sortByTotalTime(a: any, b: any) {
  return a.total_time - b.total_time;
}

function sortByTotalConnect(a: any, b: any) {
  return a.total_connect - b.total_connect;
}

function sortByBypassMaintenance(a: any, b: any) {
  return a.bypassMaintenance - b.bypassMaintenance;
}

class Player {
  steam_id: string;
  name: string;
  rank: number;
  total_time: number;
  total_connect: number;
  bypassMaintenance: boolean;

  constructor(
    steam_id: string,
    name: string,
    rank: number,
    total_time: number,
    total_connect: number,
    bypassMaintenance: boolean,
  ) {
    this.steam_id = steam_id;
    this.name = name;
    this.rank = rank;
    this.total_time = total_time;
    this.total_connect = total_connect;
    this.bypassMaintenance = bypassMaintenance;
  }
}

const ServerPlayer: Component = () => {
  const [playersList, { mutate }] = createResource("playersList", fetchPlayers);
  const [currentPlayers, setCurrentPlayers] = createSignal(new Player("", "", 0, 0, 0, false));
  const [sortInfo, setSortInfo] = createSignal({
    dsc: false,
    lastKey: "",
  });

  function sortPlayerBy(key: string) {
    let sortInfoValue = sortInfo();
    let dsc = sortInfoValue.lastKey === key ? !sortInfoValue.dsc : false;
    setSortInfo({ dsc, lastKey: key });

    mutate((prevPlayers) => {
      let players = [...prevPlayers];
      switch (key) {
        case "name":
          players.sort(sortByName);
          break;
        case "rank":
          players.sort(sortByRank);
          break;
        case "total_time":
          players.sort(sortByTotalTime);
          break;
        case "total_connect":
          players.sort(sortByTotalConnect);
          break;
        case "bypassMaintenance":
          players.sort(sortByBypassMaintenance);
          break;
      }
      if (dsc) {
        players.reverse();
      }
      return players;
    });
  }

  function getSortIcon(key: string) {
    let sortInfoValue = sortInfo();
    if (sortInfoValue.lastKey === key) {
      return sortInfoValue.dsc ? "fas fa-sort-down" : "fas fa-sort-up";
    }
    return "fas fa-sort";
  }

  async function savePlayer() {
    const res = await fetchAPI(
      `/users/:discordID/guilds/:guildID/servers/:serverID/players/${currentPlayers().steam_id}`,
      "PUT",
      currentPlayers(),
    );
    if (!res.ok) {
      return;
    }
    const ply = await res.json();
    mutate((prevPlayers) => prevPlayers?.map((p) => (p.steam_id === ply.steam_id ? ply : p)));
  }

  return (
    <>
      <AdminModal title="Edit Player" id="edit_player">
        <div class="form-control">
          <label class="label">
            <span class="label-text">ByPass Maintenance</span>
          </label>
          <select
            class="select select-bordered"
            value={currentPlayers().bypassMaintenance ? "true" : "false"}
            onChange={(e) => {
              currentPlayers().bypassMaintenance = e.currentTarget.value === "true";
            }}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        <button
          class="btn btn-primary mt-2"
          onclick="edit_player.close()"
          onClick={async () => {
            await savePlayer();
          }}
        >
          Save
        </button>
      </AdminModal>

      <AdminPanel title="Players Database" description="List of all players in the server" type="none">
        <table class="table">
          <thead>
            <tr class="text-white text-l hover:cursor-pointer">
              <th class="w-1/4" onClick={() => sortPlayerBy("name")}>
                Name <i class={getSortIcon("name")}></i>
              </th>
              <th class="text-center" onClick={() => sortPlayerBy("rank")}>
                Rank <i class={getSortIcon("rank")}></i>
              </th>
              <th class="text-center" onClick={() => sortPlayerBy("total_time")}>
                Total Time <i class={getSortIcon("total_time")}></i>
              </th>
              <th class="text-center" onClick={() => sortPlayerBy("total_connect")}>
                Total Connect <i class={getSortIcon("total_connect")}></i>
              </th>
              <th class=" w-1/6 text-center" onClick={() => sortPlayerBy("bypassMaintenance")}>
                Bypass Maintenance <i class={getSortIcon("bypassMaintenance")}></i>
              </th>
              <th class="hover:cursor-default 1/6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <Show when={!playersList.loading}>
              <For each={playersList()}>
                {(player) => (
                  <tr>
                    <td>{player.name}</td>
                    <td class="text-center">{player.rank}</td>
                    <td class="text-center">{convertSecToTime(player.total_time)}</td>
                    <td class="text-center">{player.total_connect}</td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        {player.bypassMaintenance ? (
                          <i class="fas fa-check text-green"></i>
                        ) : (
                          <i class="fas fa-times text-red"></i>
                        )}
                      </div>
                    </td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        <a
                          href={`https://steamcommunity.com/profiles/${player.steam_id}`}
                          target="_blank"
                          class="tooltip tooltip-info"
                          data-tip="Steam"
                        >
                          <i class="fa-brands fa-steam"></i>
                        </a>
                        <div class="tooltip tooltip-info" data-tip="Edit">
                          <i
                            class="hover:cursor-pointer fas fa-edit"
                            onclick="edit_player.showModal()"
                            onClick={() => setCurrentPlayers(player)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
            </Show>
          </tbody>
        </table>

        <Switch>
          <Match when={playersList.loading}>
            <div class="flex justify-center h-36">
              <div class="loading loading-spinner loading-lg"></div>
            </div>
          </Match>
          <Match when={playersList.error}>
            <tr>
              <td colSpan="4">Failed to load the links</td>
            </tr>
          </Match>
        </Switch>
      </AdminPanel>
    </>
  );
};

export default ServerPlayer;
